export const state = () => ({
  groupChats: [],
  directChats: [],
  selectedChat: null,
  unreadChats: 0,
});

export const mutations = {
  SET_SELECTED_CHAT(state, data) {
    let findGroup = state.groupChats.findIndex((e) => {
      return e.id === data.id;
    });
    if (findGroup !== -1) {
      state.groupChats[findGroup].unread_count = 0;
    }

    let findDirect = state.directChats.findIndex((e) => {
      return e.id === data.id;
    });
    if (findDirect !== -1) {
      state.directChats[findDirect].unread_count = 0;
    }
    state.selectedChat = data;
  },
  SET_READ_CHATS(state, data = 0) {
    state.selectedChat.unread_count = data;
  },
  MUTE_CONVO(state, data) {
    state.selectedChat.settings.is_muted = data;
  },
  SET_UNREAD_CHATS(state, data) {
    state.unreadChats += 1;
  },
  SET_INITIAL_UNREAD_CHATS(state, data) {
    state.unreadChats = data;
  },
  JOIN_GROUP(state, data) {
    state.selectedChat.conversation.participants.push({
      user: data,
      messageable_type: "User",
    });
    if (state.selectedChat.settings && state.selectedChat.settings.left_at) {
      state.selectedChat.settings.left_at = null;
    }
  },
  LEAVE_GROUP(state, data) {
    console.log("AAAAAAAAA");
    state.selectedChat.conversation.participants =
      state.selectedChat.conversation.participants.filter((e) => {
        return e?.user?.id !== data;
      });
    state.selectedChat.settings.left_at = new Date();
  },
  SET_SEARCHED_CHATS(state, data) {
    const chats = data.map((e) => {
      return {
        conversation_id: e?.id,
        direct_message: e?.direct_message,
        conversation: e,
        data: JSON.stringify(e?.data),
      };
    });

    state.groupChats = chats.filter((e) => {
      return !e.direct_message;
    });
    state.directChats = chats.filter((e) => {
      return e.direct_message;
    });
  },
  SET_CHATS(state, data) {
    let currentChats = data.filter((e) => {
      let userDeleted = e.conversation.participants.some((e) => {
        return !e.user;
      });
      if (!userDeleted) return e;
    });
    state.groupChats = currentChats.filter((e) => {
      return e.direct_message === 0;
    });
    state.directChats = currentChats.filter((e) => {
      return e.direct_message === 1;
    });
  },
};

export const getters = {
  getChatKey: (state) => (key) => {
    return state[key];
  },
};
