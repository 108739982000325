export default function ({ route, redirect, $auth, store }) {
  if (
    ![
      "halee.test",
      "connect.test",
      "dev-halee.vianova.ai",
      "stage-halee.vianova.ai",
      "civiclee.wearelee.org",
      "lee.vianova.ai",
      "halee.vianova.ai",
    ].includes(window.location.hostname)
  ) {
    redirect("/404");
  }
}
