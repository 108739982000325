var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sidebar",staticClass:"vertical-menu",style:([
    _vm.$route.path.includes('care-plan') ||
    _vm.$route.path.includes('calendar') ||
    _vm.$route.name === 'users-id-tracks' ||
    _vm.$route.name == 'admin-index-tracks-id' ||
    (_vm.$route.name == 'admin-index-care_plans-planId' &&
      !_vm.$route.path.includes('/alert/'))
      ? { left: '0' }
      : _vm.$route.name == 'profilea'
      ? { width: '0' }
      : _vm.$route.name.includes('chats')
      ? { width: '20rem' }
      : {} ])},[_c('div',{staticClass:"navbar-brand-box",style:([
      _vm.$route.path.includes('profilea') && !_vm.$route.path.includes('chats')
        ? { 'box-shadow': '-2.5px 2px 4px #0f223a1f' }
        : {} ])},[_c('nuxt-link',{staticClass:"logo logo-dark text-left",attrs:{"to":_vm.logoRedirect}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo_small,"alt":"","height":"22"}})]),_vm._v(" "),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"30"}})])]),_vm._v(" "),_c('nuxt-link',{staticClass:"logo logo-light text-left",attrs:{"to":_vm.logoRedirect}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo_small,"alt":"","height":"22"}})]),_vm._v(" "),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"30"}})])])],1),_vm._v(" "),(
      !_vm.$route.name.includes('chats') &&
      _vm.$route.name !== 'users-id-tracks' &&
      _vm.$route.name != 'chats' &&
      !_vm.$route.name.includes('calendar') &&
      _vm.$route.name != 'admin-index-care_plans-planId'
    )?_c('button',{staticClass:"btn btn-sm px-3 font-size-16 header-item vertical-menu-btn",attrs:{"type":"button"},on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"fa fa-fw fa-bars"})]):_vm._e(),_vm._v(" "),_c('div',{ref:"sidebar",staticClass:"sidebar-menu-scroll",attrs:{"data-simplebar":""}},[_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[(
            _vm.$route.name === 'users-id-tracks' ||
            _vm.$route.name == 'admin-index-tracks-id' ||
            (_vm.$route.name == 'admin-index-care_plans-planId' &&
              !_vm.$route.fullPath.includes('/alert/'))
          )?[_c('li',{staticClass:"menu-title"}),_vm._v(" "),_c('li',[(
                _vm.$route.name == 'admin-index-care_plans-planId' &&
                !_vm.$route.fullPath.includes('care_plans/alert')
              )?_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{ name: "admin-index-care_plans" }}},[_c('i',{staticClass:"uil-left-arrow-to-left"}),_vm._v(" "),_c('span',[_vm._v("Care Plans")])]):(_vm.$route.name == 'admin-index-tracks-id')?_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{ name: "admin-index-tracks" }}},[_c('i',{staticClass:"uil-left-arrow-to-left"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("previous_step.tracks")))])]):_vm._e()],1),_vm._v(" "),_c('li',[(_vm.$route.name === 'users-id-tracks')?_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{
                name:
                  _vm.$store.state.modules.dashboard.previousStep &&
                  [
                    'users-id-overview',
                    'users-id-overview-vitals',
                    'users-id-overview-lab-vitals',
                    'users-id-overview-medications' ].includes(_vm.$store.state.modules.dashboard.previousStep)
                    ? 'users-id-overview'
                    : _vm.$store.state.modules.dashboard.previousStep &&
                      _vm.$store.state.modules.dashboard.previousStep !==
                        'null' &&
                      _vm.$store.state.modules.dashboard.previousStep !==
                        'users-id-tracks' &&
                      _vm.$store.state.modules.dashboard.previousStep !==
                        'index-name'
                    ? _vm.$store.state.modules.dashboard.previousStep
                    : 'index',
                params: { id: _vm.itemId },
              }}},[_c('i',{staticClass:"uil-left-arrow-to-left"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$store.state.modules.dashboard.previousStep &&
                _vm.$store.state.modules.dashboard.previousStep !== "null" &&
                _vm.$store.state.modules.dashboard.previousStep !==
                  "users-id-tracks" &&
                _vm.$store.state.modules.dashboard.previousStep !== "index-name"
                  ? _vm.$t(
                      ("previous_step." + (_vm.$store.state.modules.dashboard.previousStep))
                    )
                  : _vm.$t("previous_step.index")))])]):_vm._e()],1),_vm._v(" "),_c('li'),_vm._v(" "),_c('li',[_vm._t("divider")],2),_vm._v(" "),_c('li',[_vm._t("items")],2),_vm._v(" "),_c('li',[_vm._t("divider")],2),_vm._v(" "),_c('li',[_vm._t("alerts")],2),_vm._v(" "),_c('li',[_vm._t("divider-alert")],2),_vm._v(" "),_c('div',[_vm._t("sort-by")],2),_vm._v(" "),_c('li',[_vm._t("visible-items")],2)]:_vm._l((_vm.navItems),function(item){return [(item.isTitle)?_c('li',{key:item.id,staticClass:"menu-title"},[_vm._v("\n            "+_vm._s(_vm.$t(item.label))+"\n          ")]):_vm._e(),_vm._v(" "),(!item.isTitle && !item.isLayout)?_c('li',{key:item.id,class:{ divider: item.isDivider }},[(_vm.hasItems(item))?_c('a',{staticClass:"is-parent",class:{
                'has-arrow': !item.badge,
                'has-dropdown': item.badge,
              },attrs:{"href":"javascript:void(0);"}},[(item.icon)?_c('i',{class:("" + (item.icon))}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(item.label))+" ")]),_vm._v(" "),(item.badge && item.badge.id == 'chat-badge-counter')?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right")},[_vm._v(_vm._s(_vm.unreadCount))]):(item.badge)?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right"),attrs:{"id":item.badge.id}},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.hasItems(item) && item.display && !item.isDivider)?_c('nuxt-link',{key:item.link,staticClass:"side-nav-link-ref",style:([
                _vm.$route.fullPath.includes('admin') ? _vm.adminMenuStyle : {} ]),attrs:{"to":item.is_canna
                  ? { name: 'cannahealrx-appointments' }
                  : (_vm.$vianovaConfig.slug === 'oasis' ||
                      _vm.$vianovaConfig.slug === 'am-diabetes' ||
                      _vm.$vianovaConfig.slug === 'sayf') &&
                    item.id === 13
                  ? item.link
                  : _vm.itemId &&
                    item.link != '/' &&
                    _vm.$route.fullPath.includes('users')
                  ? {
                      name: ("users-id-" + (item.link)),
                      params: { id: _vm.itemId },
                    }
                  : _vm.previousRoute == 'index-name'
                  ? "/"
                  : _vm.previousRoute
                  ? ("/" + _vm.previousRoute)
                  : _vm.previousRouteComp() != 'null'
                  ? ("/" + (_vm.previousRouteComp()))
                  : item.link}},[(item.icon)?_c('i',{class:("" + (item.icon))}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(item.label) == "Dashboard" &&
                ((_vm.previousRoute && _vm.previousRoute != "patients") ||
                  (!_vm.previousRoute && _vm.previousRouteComp() != "patients"))
                  ? "Dashboard"
                  : _vm.$t(item.label) == "Dashboard" &&
                    (_vm.previousRoute == "patients" ||
                      _vm.previousRouteComp() == "patients")
                  ? "Patients"
                  : _vm.$t(item.label)))]),_vm._v(" "),(item.badge && item.badge.id == 'chat-badge-counter')?_c('span',[(_vm.unreadCount)?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right")},[_vm._v("New")]):_vm._e()]):(item.badge)?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right"),attrs:{"id":item.badge.id}},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),_vm._v(" "),(item.isDivider)?_c('span'):_vm._e(),_vm._v(" "),(_vm.hasItems(item))?_c('ul',{staticClass:"sub-menu",attrs:{"aria-expanded":"false"}},_vm._l((item.subItems),function(subitem,index){return _c('li',{key:index},[(!_vm.hasItems(subitem))?_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":_vm.$route.fullPath.includes('users')
                      ? {
                          name: ("users-id-" + (subitem.link)),
                          params: { id: _vm.itemId },
                        }
                      : subitem.link}},[_vm._v(_vm._s(_vm.$t(subitem.label))+"\n                ")]):_vm._e(),_vm._v(" "),(_vm.hasItems(subitem))?_c('a',{staticClass:"side-nav-link-a-ref has-arrow",attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(_vm.$t(subitem.label))+"\n                ")]):_vm._e(),_vm._v(" "),(_vm.hasItems(subitem))?_c('ul',{staticClass:"sub-menu mm-collapse",attrs:{"aria-expanded":"false"}},_vm._l((subitem.subItems),function(subSubitem,index){return _c('li',{key:index},[_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":subSubitem.link}},[_vm._v(_vm._s(_vm.$t(subSubitem.label))+"\n                    ")])],1)}),0):_vm._e()],1)}),0):_vm._e()],1):_vm._e()]}),_vm._v(" "),(
            (_vm.userGroups.length > 0 || _vm.historicalGroups.length > 0) &&
            _vm.isUserOverviewPage
          )?[_c('li',{staticClass:"menu-title"},[_vm._v("ALL COHORTS")]),_vm._v(" "),_vm._l((_vm.userGroups),function(group){return _c('li',{key:group.id},[_c('div',{staticClass:"cohort-item px-3 py-2",class:{ active: _vm.isSelectedCohort(group) },on:{"click":function($event){return _vm.selectCohort(group)}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"cohort-name"},[_vm._v("\n                  "+_vm._s(group.name)+"\n                  "),(group && group.status === 'active')?_c('i',{staticClass:"mdi mdi-star text-warning"}):_vm._e()])])])])}),_vm._v(" "),_vm._l((_vm.historicalGroups),function(group){return _c('li',{key:'inactive-' + group.id},[_c('div',{staticClass:"cohort-item px-3 py-2",class:{ active: _vm.isSelectedCohort(group) },on:{"click":function($event){return _vm.selectCohort(group)}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"cohort-name text-muted"},[_vm._v("\n                  "+_vm._s(group.name)+" (Inactive)\n                ")])])])])})]:_vm._e()],2),_vm._v(" "),(_vm.$vianovaConfig.options.support_portal)?_c('div',{staticClass:"ml-4"},[_c('a',{attrs:{"href":_vm.$vianovaConfig.options.support_portal,"target":"_blank"}},[_c('b',[_vm._v(_vm._s(_vm.$t("footer.need_assistance")))])])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }